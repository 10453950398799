import request from "@/utils/request";

export function getSubject(data) {
  return request({
    method: "post",
    url: "/subject/get",
    data,
  });
}

export function addSubject(data) {
  return request({
    method: "post",
    url: "/subject/add",
    data,
  });
}

export function editSubject(data) {
  return request({
    method: "post",
    url: "/subject/edit",
    data,
  });
}

export function deleteSubject(data) {
  return request({
    method: "post",
    url: "/subject/delete",
    data,
  });
}
