import request from "@/utils/request";

export function getClass(data) {
  return request({
    method: "post",
    url: "/classroom/get",
    data,
  });
}

export function addClass(data) {
  return request({
    method: "post",
    url: "/classroom/add",
    data,
  });
}

export function editClass(data) {
  return request({
    method: "post",
    url: "/classroom/edit",
    data,
  });
}

export function deleteClass(data) {
  return request({
    method: "post",
    url: "/classroom/delete",
    data,
  });
}
