<template>
  <div id="elearning-form">
    <div class="d-flex justify-space-between mb-4">
      <div class="d-flex align-center">
        <router-link to="e-learning"
          ><v-icon color="black">mdi-arrow-left</v-icon></router-link
        >
        <span class="body-1 ml-2">{{
          $route.query.id ? "Ubah E-Learning" : "E-Learning Baru"
        }}</span>
      </div>
      <v-btn color="primary" depressed @click="submit">Simpan</v-btn>
    </div>

    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card flat class="pa-4">
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="form.school_year"
              :rules="[(v) => !!v || 'Tahun ajaran harus diisi']"
              :items="schoolYearList"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              placeholder="Tahun Ajaran"
            >
              <template v-slot:selection="{ item }">
                {{ item.label }}, Semester {{ item.semester }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.label }}, Semester {{ item.semester }}
                <v-icon v-if="item.is_active" class="ml-2" color="success"
                  >mdi-checkbox-marked</v-icon
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              v-model="form.classroom"
              :rules="[(v) => !!v || 'Kelas harus diisi']"
              :items="classList"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              placeholder="Kelas"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              v-model="form.subject"
              :rules="[(v) => !!v || 'Mata pelajaran harus diisi']"
              :items="subjectList"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              placeholder="Mata Pelajaran"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.title"
              :rules="[(v) => !!v || 'Judul harus diisi']"
              name="elearning-title"
              label="Judul"
              hide-details="auto"
              dense
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.youtube"
              :rules="[(v) => !v || getUrl(form.youtube) || 'Link tidak valid']"
              name="elearning-youtube"
              label="Link Youtube"
              hide-details="auto"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="body-2 mb-1 font-weight-bold">Unggah File</div>
            <div v-if="this.form.file">
              <div
                style="position: relative"
                :style="
                  $vuetify.breakpoint.xsOnly ? 'width: 100%' : 'width: 35vw'
                "
              >
                <iframe :src="form.file" style="height: 50vh; width: 96%" />
                <v-btn
                  icon
                  small
                  color="error"
                  style="position: absolute; right: -20px; top: 0"
                  @click="removeFileHandler"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </div>
            </div>
            <div v-else class="d-flex align-center">
              <UploadFile
                color="btn-upload"
                depressed
                accept="application/pdf"
                @getDocument="(fileData) => (fileUpload = fileData)"
              />

              <span v-if="fileUpload" class="ml-2">{{ fileUpload.name }}</span>
            </div>
          </v-col>
          <v-col cols="12">
            <quill-editor
              ref="myQuillEditor"
              v-model="form.description"
              :options="editorOption"
              :class="{ 'error-description-quill': formDescriptionError }"
              aria-placeholder="Deskripsi"
              @input="descriptionHandler()"
            />

            <span
              v-if="formDescriptionError"
              class="v-application error--text v-messages"
              style="padding: 0 12px; margin-top: 3px"
              >Deskripsi harus diisi</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import UploadFile from "@/components/UploadFile";

import { addElearning, editElearning, detailElearning } from "@/api/elearning";
import { getSchoolYear } from "@/api/admin/schoolYear";
import { getClass } from "@/api/admin/class";
import { getSubject } from "@/api/admin/subject";
import { uploadFile, removeFile } from "@/api/file";

export default {
  components: {
    quillEditor,
    UploadFile,
  },
  data() {
    return {
      valid: false,
      form: {
        subject: null,
        school_year: null,
        classroom: null,
        title: "",
        youtube: null,
        file: null,
        description: null,
      },
      fileUpload: null,
      formDescriptionError: false,
      schoolYearList: [],
      classList: [],
      subjectList: [],
      deleteFile: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
    };
  },
  async created() {
    this.$store.commit("SET_GLOBAL_LOADING", true);
    await getSchoolYear().then((res) => {
      if (res.data.code) {
        this.schoolYearList = res.data.data;
        res.data.data.map((r) => {
          if (r.is_active) this.form.school_year = r.id;
        });
      }
    });
    await getClass().then((res) => {
      if (res.data.code) this.classList = res.data.data;
    });
    await getSubject().then((res) => {
      if (res.data.code) this.subjectList = res.data.data;
    });

    if (this.$route.query.id) {
      detailElearning({ id: this.$route.query.id })
        .then((res) => {
          if (res.data.code) {
            this.form = res.data.data;
            setTimeout(() => {
              this.form.description = res.data.data.description;
            }, 200);
          }
          this.$store.commit("SET_GLOBAL_LOADING", false);
        })
        .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
    } else this.$store.commit("SET_GLOBAL_LOADING", false);
  },
  computed: {
    errorDescription() {
      return !this.form.description;
    },
  },
  methods: {
    removeFileHandler() {
      this.deleteFile = this.form.file;
      setTimeout(() => {
        this.form.file = null;
      }, 100);
    },
    descriptionHandler() {
      this.formDescriptionError = false;
      if (this.errorDescription) {
        this.formDescriptionError = true;
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.form.description = "";
      this.fileUpload = null;
      setTimeout(() => {
        this.formDescriptionError = false;
      }, 50);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let type = "add";
        if (this.form.id) type = "edit";

        if (this.errorDescription) {
          this.formDescriptionError = true;
        } else {
          this.$store.commit("SET_GLOBAL_LOADING", true);
          this.formDescriptionError = false;
          let query = { ...this.form };

          if (this.deleteFile) {
            await removeFile({ file: this.deleteFile })
              .then((res) => {
                if (!res.data.code) {
                  this.$store.commit("SET_SNACKBAR", {
                    value: true,
                    text: res.data.message,
                    color: "error",
                  });
                  this.$store.commit("SET_GLOBAL_LOADING", false);
                }
              })
              .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
          }

          if (this.fileUpload) {
            const bodyImage = new FormData();
            bodyImage.append("file", this.fileUpload);

            await uploadFile(bodyImage)
              .then((res) => {
                if (res.data.code) {
                  query.file = res.data.data.path;
                  this.actionData(query, type);
                } else {
                  this.$store.commit("SET_SNACKBAR", {
                    value: true,
                    text: res.data.message,
                    color: "error",
                  });
                  this.$store.commit("SET_GLOBAL_LOADING", false);
                }
              })
              .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
          } else {
            this.actionData(query, type);
          }
        }
      }

      // custom error quill description
      this.descriptionHandler();
    },
    actionData(data, type) {
      switch (type) {
        case "add":
          addElearning(data)
            .then((res) => {
              if (res.data.code) {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: "Berhasil menambah elearning",
                  color: "success",
                });
                this.resetForm();
              } else {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: res.data.message,
                  color: "error",
                });
              }
              this.$store.commit("SET_GLOBAL_LOADING", false);
            })
            .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
          break;
        case "edit":
          editElearning(data)
            .then((res) => {
              if (res.data.code) {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: "Berhasil mengubah elearning",
                  color: "success",
                });
                this.resetForm();
                this.$router.push(`e-learning`);
              } else {
                this.$store.commit("SET_SNACKBAR", {
                  value: true,
                  text: res.data.message,
                  color: "error",
                });
              }
              this.$store.commit("SET_GLOBAL_LOADING", false);
            })
            .catch(() => this.$store.commit("SET_GLOBAL_LOADING", false));
          break;
        default:
          break;
      }
    },
    getUrl(url) {
      if (url) {
        let video_id = url.split("v=")[1];
        if (video_id) {
          const ampersandPosition = video_id.indexOf("&");
          if (ampersandPosition != -1) {
            video_id = video_id.substring(0, ampersandPosition);
          }
          const splitUrl = url.split("watch?v=");
          if (splitUrl[0] != "https://www.youtube.com/" || !splitUrl[1]) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
#elearning-form {
  .error-description-quill {
    border: 1.5px solid #ff5252 !important;
    border-radius: 5px;
    .ql-toolbar.ql-snow,
    .ql-editor {
      border-radius: 5px;
    }
  }
  .btn-upload {
    background: #4886ff;
    color: white;
  }
}
</style>
