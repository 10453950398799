import request from "@/utils/request";

export function getElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/list",
    data,
  });
}

export function addElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/add",
    data,
  });
}

export function editElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/edit",
    data,
  });
}

export function deleteElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/delete",
    data,
  });
}

export function detailElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/detail",
    data,
  });
}

export function readListElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/read-list",
    data,
  });
}

export function likeElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/like",
    data,
  });
}

export function likeListElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/like-list",
    data,
  });
}

export function commentElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/comment",
    data,
  });
}

export function commentListElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/comment-list",
    data,
  });
}

export function commentDeleteElearning(data) {
  return request({
    method: "post",
    url: "/e-learning/comment-delete",
    data,
  });
}
