import request from "@/utils/request";

export function addSchoolYear(data) {
  return request({
    method: "post",
    url: "/school-year/add",
    data,
  });
}

export function getSchoolYear(data) {
  return request({
    method: "post",
    url: "/school-year/get",
    data,
  });
}

export function editSchoolYear(data) {
  return request({
    method: "post",
    url: "/school-year/edit",
    data,
  });
}

export function setActiveSchoolYear(data) {
  return request({
    method: "post",
    url: "/school-year/set-active",
    data,
  });
}

export function deleteSchoolYear(data) {
  return request({
    method: "post",
    url: "/school-year/delete",
    data,
  });
}

export function listSchoolYear(data) {
  return request({
    method: "post",
    url: "/school-year/dropdown",
    data,
  });
}
